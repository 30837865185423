import React from 'react';
import Layout from '../components/Layout';

const termsAndConditions = (): React.ReactElement => {
  return (
    <Layout>
      <div id="termsAndConditions">
        <h1>Termos e Condições</h1>
        <h2>Consulte os Termos e Condições de Utilização da Plataforma SUOP</h2>
        <p>
          O Utilizador deverá ler os presentes Termos e Condições antes de proceder ao acesso e
          registo dos Serviços da <strong>SUOP</strong>, designadamente da sua{' '}
          <strong>Plataforma</strong>.
        </p>
        <p>
          Após a leitura e compreensão dos Termos e Condições, o Utilizador declara aceitá-los, sem
          necessidade de qualquer ato ou consentimento posterior.
        </p>
        <h3>A. OBJETO</h3>
        <ul>
          <li>
            O presente documento estabelece os Termos e Condições que regem a utilização dos
            Serviços que a <strong>SUOP</strong> lhe presta, designadamente o registo e acesso à{' '}
            <strong>Plataforma</strong>.<br />
            &nbsp;
          </li>
          <li>
            A <strong>Plataforma </strong>é gerida pela Walking Diagonal Lda e administrada por uma
            equipa de moderação.
            <br />
            &nbsp;
          </li>
          <li>
            A utilização dos Serviços da <strong>SUOP</strong> pressupõe o conhecimento e aceitação
            dos presentes Termos e Condições.
            <br />
            &nbsp;
          </li>
          <li>
            Quaisquer novas funcionalidades ou serviços que sejam disponibilizados pela{' '}
            <strong>SUOP</strong>, serão regulados pelos presentes Termos e Condições.
            <br />
            &nbsp;
          </li>
          <li>
            A <strong>SUOP</strong> reserva-se no direito de, a todo o tempo, alterar ou rever os
            presentes Termos e Condições, sendo qualquer alteração divulgada em www.suopapp.com.
          </li>
        </ul>
        <p>&nbsp;</p>
        <h3>B. INFORMAÇÃO GERAL</h3>
        <p>
          A <strong>SUOP</strong> é um serviço online que proporciona aos utilizadores um{' '}
          <em>website </em>na <em>internet </em>e uma <strong>Plataforma </strong>para que os mesmos
          disponibilizem informação relativa a um determinado produto/artigo após a validação da
          publicitação do produto/artigo na <em>app </em>por parte da SUOP.
        </p>
        <p>
          Os presentes termos e condições de utilização do <em>website </em>e da aplicação detida
          pela sociedade Walking Diagonal Lda, inscrita na Conservatória de Registo Comercial de
          Lisboa com o número de pessoa coletiva n.º 515901105 com o capital social de €10.000,00,
          com sede na Rua de São Bernardo nº22, 1200-825 Lisboa, ("
          <b>
            <span>SOUP</span>
          </b>
          "), definem os termos em que os produtos aí disponibilizados poderão ser apresentados e
          divulgados (doravante denominados "Termos e Condições").
        </p>
        <p>
          Os presentes Termos e Condições de utilização dos Serviços disponibilizados pela{' '}
          <strong>SUOP</strong>, aplicam-se a todos os utilizadores dos presentes Serviços
          (titulares dos artigos/produtos) que pretendem apresentar e divulgar os seus
          artigos/produtos através dos Serviços da <strong>SUOP</strong>, designadamente através da{' '}
          <strong>Plataforma</strong>. A prestação dos Serviços está dependente da total aceitação
          destes Termos e Condições pelos seus utilizadores e, caso os mesmo não estejam de acordo
          com estes Termos e Condições ou não se comprometam a comportar-se de acordo com estes, não
          poderão utilizar os referidos Serviços.
        </p>
        <p>
          Assim, para que um visitante do <em>website </em>da <strong>SUOP</strong> que seja titular
          de um artigo/produtos possa apresentar e divulgar esses artigos/produtos (doravante
          designados por “utilizadores”) através da aplicação é necessário (i) que aceitem os
          presentes Termos e Condições e (ii) que se registem na aplicação <strong>SUOP</strong> (
          <strong>Plataforma</strong>).
        </p>
        <p>
          Para proceder à troca de artigos/produtos, o utilizador deve ser maior de idade ou menor
          emancipado nos termos da Lei. Os elementos e informações transmitidos pelo utilizador à{' '}
          <strong>SUOP</strong> gozarão de plenos efeitos jurídicos.
        </p>
        <p>
          A <strong>SUOP</strong> reserva-se no direito de alterar estes Termos e Condições sem
          aviso prévio para benefício dos utilizadores, sendo quaisquer alterações publicadas no{' '}
          <em>website</em>. O utilizador deve consultar os Termos e Condições, disponíveis para
          consulta no <em>website </em>e na <em>App</em>, com regularidade de forma a tomarem
          conhecimento de eventuais alterações.
        </p>
        <p>
          Com o objetivo de proceder à gestão dos conteúdos dos Serviços a <strong>SUOP</strong>{' '}
          selecionará moderadores, os quais terão funções de gestão e organização da{' '}
          <strong>Plataforma </strong>e de validação dos produtos e artigos inseridos pelos
          utilizadores.
        </p>
        <p>
          A <strong>SUOP</strong> reserva-se no direito de apagar ou editar mensagens que não
          respeitem o disposto nos presentes Termos e Condições, sem necessidade de aviso prévio.
        </p>
        <p>
          A <strong>SUOP</strong> não será responsável por quaisquer utilizações não autorizadas da{' '}
          <strong>Plataforma </strong>nem pelos eventuais danos daí decorrentes.
        </p>
        <p>&nbsp;</p>
        <h3>C. INFORMAÇÃO SOBRE OS PRODUTOS/ARTIGOS APRESENTADOS NA APP</h3>
        <p>
          A <strong>SUOP</strong> permite que os seus utilizadores troquem impressões sobre os
          produtos/artigos apresentados através do seu <em>chat</em>, podendo aí ser fornecidos os
          elementos essenciais sobre os produtos/artigos. A <strong>SUOP</strong> terá acesso,
          através de <em>backoffice</em>, às mensagens trocadas pelo utilizador da <em>App</em>.
        </p>
        <p>
          Esta possibilidade disponibilizada aos utilizadores da <strong>Plataforma </strong>é uma
          funcionalidade exclusiva da <em>APP</em>, não estando disponível através do{' '}
          <em>Website</em>.
        </p>
        <p>
          Os produtos/artigos passíveis de troca na <strong>Plataforma </strong>são disponibilizados
          numa montra virtual no <em>website </em>e <strong>Plataforma</strong>, sendo possível
          visualizar uma ou mais imagens e uma breve descrição de cada artigo.&nbsp;
        </p>
        <p>
          As imagens apresentadas no <em>website </em>e na <strong>Plataforma </strong>são meramente
          ilustrativas. Assim, a <strong>SUOP</strong> aconselha o utilizador a consultar a
          descrição do produto/artigo no <em>website </em>e <em>App </em>de modo a obter informação
          completa acerca das respetivas características dos mesmos.
        </p>
        <p>
          Não obstante, a <strong>SUOP</strong> pode, a qualquer momento, rever as informações e
          imagens relativas aos produtos/artigos.&nbsp;
        </p>
        <p>
          A <strong>SUOP</strong> não tem controlo sobre os artigos disponibilizados no{' '}
          <em>website </em>e na <em>App</em>, nem sobre a sua proveniência, na medida em que estes
          pertencem aos seus titulares, e como tal, não garante a qualidade, segurança ou legalidade
          dos artigos/produtos, a veracidade ou exatidão destes, ou a capacidade dos utilizadores
          para permutarem os produtos/artigos. A <strong>SUOP</strong> não se compromete a verificar
          ou aprovar, de forma vinculativa ou sistemática, os artigos/produtos colocados na montra
          visual do <em>website </em>e da <strong>Plataforma </strong>pelos utilizadores: estes são
          colocados online de forma automática e sem a sua interferência.&nbsp;
        </p>
        <p>
          As informações sobre os produtos/artigos podem ser escritas na língua que os utilizadores
          desejarem.
        </p>
        <p>
          Não é permitido aos titulares dos artigos/produtos disponibilizarem na montra visual do{' '}
          <em>website </em>e da <strong>Plataforma </strong>produtos/artigos que contenham
          manipulação de palavras ou que sejam atribuídos a categorias inapropriadas (i.e., conteúdo
          vulgar ou ofensivo, que viole as regras de etiqueta social, que promova o ódio e a
          violência, o racismo, a xenofobia ou conflitos entre nações), podendo a{' '}
          <strong>SUOP</strong>, segundo estes termos e condições, cancelar a disponibilização dos
          referidos produtos.
        </p>
        <p>
          A <strong>SUOP</strong> também não se responsabiliza pela descrição feita de um
          produto/artigo. Caso o titular do produto/artigo infrinja, num determinado anúncio de um
          produto/artigo, as regras vigentes este assumirá toda e qualquer responsabilidade que
          resulte em danos ou prejuízos perante qualquer entidade, pessoa singular ou coletiva.
        </p>
        <p>
          No caso de os utilizadores pretenderem optar pela venda dos produtos disponibilizados na{' '}
          <em>App</em>, em vez de os trocarem, ou se optarem por trocar os produtos, mas estipularem
          ainda o pagamento de um qualquer montante, tal situação é da inteira responsabilidade dos
          mesmos, encontrando-se fora do âmbito de intervenção da <strong>SUOP</strong>.
        </p>
        <p>
          <u>Os titulares dos artigos comprometem-se a</u>:
        </p>
        <ol>
          <li>
            Disponibilizar os produtos/artigos anunciados somente se não estiverem onerados por
            direitos de terceiros e com disponibilidade imediata.
          </li>
        </ol>
        <p>&nbsp;</p>
        <h3>D. OBRIGAÇÕES DOS UTILIZADORES</h3>
        <p>
          <u>Ao aceitar os presentes Termos e Condições, os utilizadores obrigam-se a</u>:
        </p>
        <ol>
          <li>
            Absterem-se de introduzir, armazenar ou difundir através do <em>website </em>ou{' '}
            <em>App </em>conteúdos difamatórios, obscenos, injuriosos, xenófobos e/ou de qualquer
            outra índole que violem os princípios gerais de direito e a ordem pública ou que lesem,
            de alguma forma, posições juridicamente protegidas; e a não apresentar ofertas de venda
            ou locação, venda de artigos de luxo de carácter violento, provocativo, ofensivo,
            racista ou suscetível de violar a privacidade ou direitos de terceiros;
            <br />
            &nbsp;
          </li>
          <li>
            Fornecerem e manterem os dados pessoais verídicos, completos e atualizados;
            <br />
            &nbsp;
          </li>
          <li>
            Guardarem e manterem confidenciais as suas senhas de entrada no <em>website </em>e na{' '}
            <em>App</em>, de forma a obstar que terceiros acedam às suas contas, fazendo uma
            utilização rigorosa, exclusivamente pessoal e assumindo todos os riscos inerentes à sua
            divulgação indevida;
            <br />
            &nbsp;
          </li>
          <li>
            Compromete-se a informar imediatamente a <strong>SUOP</strong> de quaisquer violações ou
            utilizações indevidas da Palavra-Passe (<em>Password</em>) de que tenha tido
            conhecimento;
            <br />
            &nbsp;
          </li>
          <li>
            Memorizar a sua Palavra-Passe e proceder à sua alteração com regularidade, devendo,
            ainda, verificar sempre a data/hora do último login efetuado na <em>App </em>e manter em
            local seguro essa informação, por forma a garantir que não existirão quaisquer acessos
            indevidos;
            <br />
            &nbsp;
          </li>
          <li>
            Não usarem identidades falsas ou imitar terceiros;
            <br />
            &nbsp;
          </li>
          <li>
            Absterem-se de utilizar os Serviços da <strong>SUOP</strong> de qualquer forma que viole
            qualquer lei aplicável ou regulação, ou seja ilegal ou fraudulenta, incluindo transmitir
            ou facilitar o envio de qualquer publicidade ou promoção não solicitada ou autorizada ou
            qualquer outra forma ou solicitação similar (<em>spam</em>);
            <br />
            &nbsp;
          </li>
          <li>
            O utilizador não deverá partilhar ou dirigir insultos ou quaisquer comentários
            impróprios a outros utilizadores e/ou moderadores nem criar situações de conflito no{' '}
            <em>chat</em>;
            <br />
            &nbsp;
          </li>
          <li>
            Não transmitirem qualquer material que contenha vírus,{' '}
            <em>Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware</em> ou
            qualquer outro programa lesivo ou código de computador similar destinado a criar
            adversidades à utilização dos Serviços;
            <br />
            &nbsp;
          </li>
          <li>
            Cumprir todas as obrigações decorrentes das trocas dos produtos/artigos comunicados e
            divulgados através dos anúncios na <strong>SUOP</strong>;<br />
            &nbsp;
          </li>
          <li>
            Enquanto titular de um artigo/produto sujeito a possível permuta, assumir a total e
            exclusiva responsabilidade pela veracidade dos dados do artigo/produto como a
            responsabilidade pela legalidade do mesmo;
            <br />
            &nbsp;
          </li>
          <li>
            Caso o utilizador insira quaisquer conteúdos de terceiros no <em>website </em>e na{' '}
            <em>App</em>, deverá declarar que o faz mediante autorização do titular dos dados de
            registo no <em>website </em>e na <em>App</em>, atuando em sua representação;
            <br />
            &nbsp;
          </li>
          <li>
            O utilizador é o único responsável pelos danos que cause a terceiros pela divulgação de
            dados de que não é titular ou não tenha autorização para partilhar.
          </li>
        </ol>
        <p>&nbsp;</p>
        <h3>E. REGISTO E UTILIZAÇÃO DA SUOPAPP</h3>
        <p>O utilizador terá de se registar na SUOPAPP.</p>
        <p>
          O registo na SUOPAPP permite o acesso a serviços e funcionalidades exclusivamente
          destinados a utilizadores da SUOAPP.
        </p>
        <p>O registo é efetuado mediante o preenchimento do formulário de registo.</p>
        <p>
          No momento do registo, na SUOAPP o utilizador deverá escolher um Nome de Utilizador (
          <em>Username</em>), com o qual se pretende apresentar no <em>chat </em>da <em>App</em>, e
          Palavra-Passe (<em>Password</em>), por forma a garantir a legitimidade e confidencialidade
          do seu acesso.
        </p>
        <p>
          O Nome de Utilizador (<em>Username</em>) escolhido não deverá fazer referência a marcas,
          produtos ou serviços, bem como não deverá utilizar expressões ofensivas.
        </p>
        <p>
          É expressamente proibido fazer-se passar por outro utilizador, moderador ou colaborador{' '}
          <strong>SUOP</strong>.<br />O utilizador poderá escolher uma fotografia de perfil. Caso
          opte por não o fazer, em vez da mesma, aparecerá associado ao seu perfil a imagem de um
          avatar.
        </p>
        <p>
          A fotografia escolhida não poderá constituir uma imagem que, pelas suas características,
          seja considerada pela <strong>SUOP </strong>imprópria, obscena ou chocante, caso em que
          será removida sem aviso prévio.
        </p>
        <p>
          O utilizador assume total e exclusiva responsabilidade pela fotografia de perfil
          utilizada, declarando ser seu titular e respeitar os direitos de personalidade e de imagem
          ou de propriedade intelectual.
        </p>
        <p>
          O utilizador assume total e exclusiva responsabilidade pela autenticidade, exatidão,
          veracidade e atualidade dos dados fornecidos no pedido de registo.
        </p>
        <p>
          Com o registo na <em>App</em>, o utilizador reconhece que é o único e exclusivo
          responsável pela sua conta nesta <em>App</em>, bem como por toda a atividade produzida e
          conteúdos inseridos.
        </p>
        <p>
          A <strong>SUOP </strong>assegura a confidencialidade dos dados fornecidos no processo de
          registo e garante ao seu titular o acesso aos mesmos em qualquer altura.
        </p>
        <p>
          A confirmação do registo e o acesso à <em>App </em>implicam a aceitação plena e sem
          reservas dos presentes Termos e Condições.&nbsp;
        </p>
        <p>
          A <strong>SUOP </strong>aconselha ainda que os utilizadores guardem uma cópia digital ou
          física dos presentes termos e condições de utilização.
        </p>
        <p>
          Depois de o utilizador estar devidamente registado, deverá seguir todos os passos para que
          possa visualizar a montra de produtos/artigos e, finalmente, caso tenha interesse nos
          mesmos, seguir também os passos para a apresentação e descrição dos produtos/artigos ao
          outro utilizador através do <em>chat </em>e a eventual convenção de troca.
        </p>
        <p>
          É responsabilidade da <strong>SUOP </strong>arquivar todas as comunicações efetuadas com o
          utilizador.
        </p>
        <p>&nbsp;</p>
        <h4>a. MODOS DE PAGAMENTO</h4>
        <p>
          A <strong>SUOP </strong>tem por objetivo colocar os seus utilizadores em contacto,
          cruzando os interesses de cada um nas trocas de produtos/artigos a realizar. Havendo
          interesse mútuo, os utilizadores combinam os moldes em que a troca se realiza. Assim, a
          utilização da <strong>Plataforma </strong>não se compatibiliza, no seu objeto, com a
          realização de qualquer pagamento.&nbsp;
        </p>
        <p>
          No caso de algum pagamento ser acordado por parte dos intervenientes, tal situação será da
          inteira responsabilidade dos mesmos.&nbsp;
        </p>
        <p>
          No mais, a <strong>SUOP </strong>dará prioridade a produtos que estejam fisicamente mais
          perto do utilizador, no entanto.
        </p>
        <p>
          A SUOPAPP é de utilização gratuita. No entanto, para aceder a certas funcionalidades, será
          necessário subscrever uma conta <em>premium</em>, que terá um custo associado.
        </p>
        <p>
          Os utilizadores <em>premium </em>terão os seguintes benefícios:
        </p>
        <ol type="i">
          <li>
            Os seus produtos serão destacados na <strong>Plataforma</strong>;<br />
            &nbsp;
          </li>
          <li>
            Não será limitado o número de produtos/artigos adicionados na{' '}
            <strong>Plataforma</strong>;
            <br />
            &nbsp;
          </li>
          <li>
            Na sua fotografia de perfil, que aparece na <strong>Plataforma </strong>de negociação,
            aparecerá um distintivo a identificar que o utilizador é um utilizador <em>premium </em>
            da <strong>Plataforma</strong>, sendo tal distintivo visível para os restantes
            utilizadores da <strong>Plataforma</strong>;<br />
            &nbsp;
          </li>
          <li>
            Conseguirá ter acesso a mecanismos de filtros de pesquisa especiais (filtro por valor e
            filtro que permite perceber quais os utilizadores que gostaram dos produtos/artigos
            disponibilizados pelo utilizador <em>premium </em>“visão raio-x”).
          </li>
        </ol>
        <p>&nbsp;</p>
        <p>
          Os custos de adesão à conta <em>premium </em>são os seguintes:
        </p>
        <ol type="i">
          <li>Subscrição de 1 (um) mês – 2,49 € (dois euros e vinte e nove cêntimos);</li>
          <li>Subscrição de 6 (seis) meses – 9,99 € (nove euros e noventa e nove cêntimos);</li>
          <li>
            Subscrição de 12 (doze) meses – 14,99 € (Catorze euros e noventa e nove cêntimos).
          </li>
        </ol>
        <p>Os custos de adesão à função “Destacar Anúncios” são os seguintes:</p>
        <ol start={4} type="i">
          <li>Subscrição de 1 (um) dia – 1,09 € (Um euro e nove cêntimos);</li>
          <li>Subscrição de 3 (três) dias – 2,29 € (Dois euros e vinte e nove cêntimos);</li>
          <li>Subscrição de 6 (seis) dias – 3,49 € (Trêseuros e quarenta e nove cêntimos).</li>
        </ol>
        <p>Os custos de adesão à função “Intervalo de Valores” são os seguintes:</p>
        <ol start={7} type="i">
          <li>Subscrição de 1 (um) mês – 1,09 € (Um euro e nove cêntimos);</li>
          <li>Subscrição de 3 (três) meses – 2,29 € (Dois euros e vinte e nove cêntimos);</li>
          <li>Subscrição de 6 (seis) meses – 3,49 € (Três euros e quarenta e nove cêntimos).</li>
        </ol>
        <p>Os custos de adesão à função “Itens Ilimitados” são os seguintes:</p>
        <ol start={10} type="i">
          <li>Subscrição de 1 (um) mês – 1,09 € (Um euro e nove cêntimos);</li>
          <li>Subscrição de 6 (seis) meses – 4,49 € (Quatro euros e quarenta e nove cêntimos);</li>
          <li>Subscrição de 12 (doze) meses – 6,99 € (Seis euros e noventa e nove cêntimos).</li>
        </ol>
        <p>Os custos de adesão à função “Visão Raio-X” são os seguintes:</p>
        <ol start={13} type="i">
          <li>Subscrição de 15 (quinze) dias – 1,09 € (Um euro e nove cêntimos);</li>
          <li>Subscrição de 2 (dois) meses – 2,29 € (Dois euros e vinte e nove cêntimos);</li>
          <li>Subscrição de 4 (quatro) meses – 3,49 € (Três euros e quarenta e nove cêntimos).</li>
        </ol>
        <p>&nbsp;</p>
        <h4>b. ENTREGAS DOS ARTIGOS</h4>
        <p>
          A entrega dos produtos/artigos é da exclusiva responsabilidade dos utilizadores sendo a{' '}
          <strong>SUOP </strong>completamente alheia ao modo (envio por correio, troca presencial,
          etc.) como os artigos são disponibilizados entre os utilizadores.
        </p>
        <p>&nbsp;</p>
        <h4>c. CANCELAMENTOS E REEMBOLSOS</h4>
        <p>
          A <strong>SUOP </strong>declina toda a responsabilidade por qualquer atraso ou
          impossibilidade de processamento da troca acordada, nomeadamente no ato de entrega,
          derivada de erro, insuficiência e desatualização dos dados do utilizador, sendo igualmente
          alheia a quaisquer pedidos de reembolso daí advenientes. Tal será da inteira
          responsabilidade dos utilizadores.
        </p>
        <p>&nbsp;</p>
        <h4>d. SUSPENSÃO E DESATIVAÇÃO DO WEBSITE E DA SUOPAPP</h4>
        <p>
          O utilizador reconhece à <strong>SUOP </strong>o direito de, em qualquer altura (de acordo
          com o seu critério exclusivo) descontinuar ou suspender a disponibilização do{' '}
          <em>website</em> e <em>App </em>e/ou parte do serviço a um ou todos os utilizadores,
          independentemente de qualquer comunicação prévia ou posterior.
        </p>
        <p>
          A suspensão ou a cessação do serviço, nos termos descritos, não origina o direito do
          utilizador ou terceiros a qualquer indemnização ou outra compensação, não podendo a{' '}
          <strong>SUOP </strong>ser responsabilizada, ou de alguma forma onerada, por qualquer
          consequência resultante da suspensão, anulação ou cancelamento do serviço.
        </p>
        <p>
          Em caso de interrupção da disponibilidade do acesso aos Serviços, a <strong>SUOP </strong>
          compromete-se a restabelecer o acesso aos mesmos logo que tal se mostre tecnicamente
          possível.
        </p>
        <p>&nbsp;</p>
        <h3>F. COMUNICAÇÕES</h3>
        <p>
          As notificações efetuadas aos utilizadores que se relacionem com os Serviços, incluindo
          eventuais alterações aos presentes Termos e Condições, serão efetuadas para o endereço de
          correio eletrónico do utilizador ou por SMS.
        </p>
        <p>
          O utilizador é responsável pela correção dos dados transmitidos à <strong>SUOP</strong>.
        </p>
        <p>&nbsp;</p>
        <h3>G. PROPRIEDADE INTELECTUAL E INDUSTRIAL</h3>
        <p>
          A <strong>SUOP </strong>é detentora do registo no INPI.{' '}
          <a
            href="https://servicosonline.inpi.pt/pesquisas/main/marcas.jsp?lang=PT"
            target="_blank"
            rel="noreferrer"
            className="termsAndConditionslink"
          >
            https://servicosonline.inpi.pt/pesquisas/main/marcas.jsp?lang=PT
          </a>
        </p>
        <p>
          Marca nacional Nº 638828. Qualquer uso ou reprodução não autorizada da marca é
          expressamente proibida.
        </p>
        <p>
          Todos os textos, comentários, logótipos, marcas, <em>softwares</em>, vídeos, ilustrações,
          obras, <em>webdesigns </em>e imagens reproduzidos ou representados nos Serviços
          encontram-se devidamente protegidos por direitos de propriedade intelectual para todo o
          mundo. Desta forma, e nos termos do Código dos Direitos de Autor e dos Direitos Conexos e
          demais legislação aplicável, apenas será autorizado o seu uso para fins privados, sem
          prejuízo de disposições mais limitativas constantes da mencionada legislação. Qualquer
          reprodução ou representação total ou parcial do <em>website </em>e <em>app </em>ou dos
          elementos nele incluídos é estritamente proibida e conduzirá ao cancelamento imediato do
          registo do utilizador no <em>website </em>e na <em>App </em>e, bem assim, ao eventual
          recurso, por parte da <strong>SUOP</strong>, aos meios legais competentes contra quem
          assim atuar.
        </p>
        <p>
          O utilizador compromete-se a respeitar na íntegra os referidos direitos, assim como
          coibir-se de praticar quaisquer atos que possam violar a lei, tais como a reprodução, a
          comercialização, a transmissão ou a colocação à disposição do público desses conteúdos ou
          quaisquer outros atos não autorizados que tenham por objeto os mesmos conteúdos.
        </p>
        <p>
          O utilizador reconhece que todo o conteúdo que conste na publicidade, destaque, promoção
          ou menção de qualquer patrocinador ou anunciante está protegido pelas leis relativas a
          direitos de autor e direitos conexos, pelas leis relativas a propriedade industrial e
          outras leis de proteção de propriedade e de publicidade.
        </p>
        <p>&nbsp;</p>
        <h3>H. SEGURANÇA E RESPONSABILIDADE</h3>
        <p>
          A <strong>SUOP </strong>não assegura, de forma alguma, e não será responsável caso:
        </p>
        <ol>
          <li>
            o serviço no <em>website </em>e <em>App </em>seja fornecido de forma ininterrupta, seja
            seguro, sem erros ou opere de forma infinita;
            <br />
            &nbsp;
          </li>
          <li>
            a qualidade de qualquer artigo, ou que qualquer outro material adquirido ou obtido
            através da <strong>SUOP </strong>preencha quaisquer expectativas do utilizador em
            relação ao mesmo;
            <br />
            &nbsp;
          </li>
          <li>
            prejuízos ou danos consequentes do incumprimento ou cumprimento defeituoso da troca dos
            produtos/artigos apresentados e disponibilizados através dos seus Serviços, não se
            responsabilizando, nomeadamente por:
            <ol type="a">
              <li>
                erros, omissões ou outras imprecisões referentes às informações disponibilizadas
                através do serviço;
                <br />
                &nbsp;
              </li>
              <li>
                atrasos, interrupções, erros e suspensões de comunicações que tenham origem em
                fatores fora do seu controlo, designadamente, quaisquer deficiências ou falhas
                causadas pela rede de comunicações ou serviços de comunicações prestados por
                terceiros, pelo sistema informático, pelos modems, pelo <em>software </em>de ligação
                ou eventuais vírus informáticos ou decorrentes do descarregamento (“
                <em>download</em>”) por meio do serviço de ficheiros infetados ou contendo vírus ou
                outras propriedades que possam afetar os equipamentos do utilizador;
                <br />
                &nbsp;
              </li>
              <li>
                pelo incumprimento ou cumprimento defeituoso que resulte da ocorrência de situações
                de força maior, ou seja, situações de natureza extraordinária ou imprevisível,
                exteriores à <strong>SUOP </strong>e que pela mesma não possam ser controladas, tais
                como incêndios, cortes de energia, explosões, guerras, tumultos, insurreições civis,
                decisões governamentais, greves, terramotos, inundações ou outros cataclismos
                naturais ou outras situações não controláveis pela <strong>SUOP </strong>que impeçam
                ou prejudiquem o cumprimento das obrigações assumidas.
                <br />
                &nbsp;
              </li>
            </ol>
          </li>
          <li>
            introdução de hiperligações no presente site, independentemente do fim desejado, sem
            autorização prévia da <strong>SUOP</strong>. Na eventualidade de existirem noutros sites
            hiperligações que permitam o acesso à <strong>SUOP</strong>, a mesma esclarece que não
            tem qualquer responsabilidade nem sobre a proveniência da página nem sobre os conteúdos
            nela inscritos. A utilização do <em>website </em>com fins abusivos é expressamente
            proibida e a SUOP reserva-se no direito de recorrer aos meios legais competentes para
            sancionar eventuais abusos ou práticas ilegais.
          </li>
        </ol>
        <p>
          O utilizador reconhece expressamente que nenhuma responsabilidade poderá ser imputada à{' '}
          <strong>SUOP </strong>relativamente a potenciais ou atuais danos que, direta ou
          indiretamente, possam resultar para o utilizador em virtude da ocorrência dos eventos
          referidos nos números do ponto anterior.
        </p>
        <p>
          O utilizador reconhece que os dados que circulam na <em>Internet </em>não estão protegidos
          contra eventuais desvios e que a comunicação de senhas de acesso ou quaisquer outros
          códigos confidenciais e demais informações sensíveis será efetuada sob a sua inteira
          responsabilidade.
        </p>
        <p>
          O utilizador é o único e exclusivo responsável por efetuar <em>back-ups </em>e cópias de
          segurança de todos os conteúdos que colocar no <em>website </em>e <em>App</em>, devendo
          realizar novo <em>upload </em>caso ocorra perda de informação.
        </p>
        <p>&nbsp;</p>
        <h3>I. LEI APLICÁVEL E FORO</h3>
        <p>
          Os presentes Termos e Condições de utilização e todas as interações efetuadas por via e
          através dos Serviços disponibilizados pela <strong>SUOP </strong>
          encontram-se sujeitas à legislação portuguesa, e as partes acordam que, em caso de
          litígio, o tribunal competente será o da comarca de Lisboa.&nbsp;
        </p>
        <p>&nbsp;</p>
        <h3>J. PREVALÊNCIA</h3>
        <p>
          Quaisquer cláusulas contratuais que hajam sido anteriormente acordadas entre o utilizador
          e a <strong>SUOP </strong>que sejam conflituantes com os presentes Termos e Condições são
          ineficazes a partir da data de aceitação destes Termos e Condições.
        </p>
        <p>&nbsp;</p>
        <h3>K. GARANTIAS DO TITULAR DOS ARTIGOS/PRODUTOS</h3>
        <p>
          O titular do artigo/produto garante que os artigos/produtos apresentados e
          disponibilizados (i) estão em conformidade com os presentes Termos e Condições, possuem as
          características e especificações disponibilizadas no <em>website </em>e <em>App</em>; e
          (ii) estão de acordo com a legislação aplicável e não correspondem a produtos
          contrafeitos.&nbsp;
        </p>
        <p>&nbsp;</p>
        <h3>L. DADOS PESSOAIS</h3>
        <p>
          O utilizador desde já consente na recolha e tratamento informático dos seus dados, quando
          aplicável, autorizando que a <strong>SUOP </strong>insira estes dados numa base
          apropriada.&nbsp;
        </p>
        <p>
          O responsável pelo tratamento dos dados é a Walking Diagonal Lda , comprometendo-se o
          utilizador a fornecer e a manter atualizados os dados pessoais e garantindo que os mesmos
          são verdadeiros.&nbsp;
        </p>
        <p>
          Os dados pessoais fornecidos pelo utilizador destinam-se à gestão de utilizadores e
          serviços da <strong>SUOP</strong>, bem como a ações de marketing de serviços da{' '}
          <strong>SUOP</strong>. Nos termos da lei em matéria de proteção de dados, é garantido aos
          utilizadores o acesso aos seus dados, podendo solicitar por escrito a sua atualização,
          correção ou eliminação, bem como o direito de, a qualquer momento, solicitar que os seus
          dados não sejam utilizados para ações de marketing de serviços da <strong>SUOP</strong>.
        </p>
        <p>&nbsp;</p>
        <h3>M. INVALIDADE</h3>
        <p>
          Caso uma disposição constante dos presentes Termos e Condições ou qualquer alteração
          subsequente, sejam ou se tornem inválidos, a validade das restantes disposições ou acordos
          não será afetada.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Lisboa, 01-01-2021</strong>
        </p>
      </div>
    </Layout>
  );
};

export default termsAndConditions;
